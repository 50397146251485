var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from './axios';
import cookie from './cookie';
export function getMountItem(board, externalSize, apertureSize, incUndermount, quantity, isMuseum) {
    if (quantity === void 0) { quantity = 1; }
    if (isMuseum === void 0) { isMuseum = false; }
    return {
        sku: "MCTS-".concat(board.stock_lookup_sku),
        qty: quantity,
        attributes: {
            "External Size": "".concat(externalSize.width, "x").concat(externalSize.length, "mm"),
            "Aperture Size": "".concat(apertureSize.width, "x").concat(apertureSize.length, "mm"),
            'Undermount': incUndermount ? (!isMuseum ? 'Yes' : 'Yes (Museum Grade)') : 'No'
        }
    };
}
/**
 * Add an item to the cart
 * @param item Item to be added to the cart
 * @returns Promise<boolean>
 */
export function addToCart(item) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('cart/add', item)];
                case 1:
                    _a.sent();
                    return [2 /*return*/, true];
            }
        });
    });
}
export function getCart() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get('cart/contents')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function emptyCart() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('cart/empty')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.success];
            }
        });
    });
}
export function updateQuantity(itemId, quantity) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios
                        .post('cart/update', { id: itemId, quantity: quantity })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.success];
            }
        });
    });
}
export function deleteItem(itemId) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios
                        .post('cart/delete', { id: itemId })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.success];
            }
        });
    });
}
export function setPostcode(postcode) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios
                        .post('cart/shippingPostcode', { postcode: postcode })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.success];
            }
        });
    });
}
export function setCollection() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios
                        .post('cart/shippingZone', { zoneName: 'Collection' })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.success];
            }
        });
    });
}
export function setVatMode(mode) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios
                        .post('cart/vatSwitch', { mode: mode })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.success];
            }
        });
    });
}
export function isEmailValid(email) {
    var EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return EMAIL_REGEX.test(email);
}
export function getDueDate() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios
                        .get('dueDates/forProductType', { params: { productTypeId: 16 } })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function getCartId() {
    return cookie.get('cart_id') || null;
}
export function setCartId(id) {
    cookie.set('cart_id', id);
}

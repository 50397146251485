var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { BContainer, BNavbar, BCollapse, BNavbarNav, BNavItem, BNavbarToggle } from 'bootstrap-vue';
import VueBus from 'vue-bus';
import AppHeader from '@/vue/components/layout/Header.vue';
import AppFooter from '@/vue/components/layout/Footer.vue';
Vue.use(VueBus);
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    App = __decorate([
        Component({
            components: {
                AppHeader: AppHeader,
                AppFooter: AppFooter,
                BContainer: BContainer,
                BNavbar: BNavbar,
                BCollapse: BCollapse,
                BNavbarNav: BNavbarNav,
                BNavItem: BNavItem,
                BNavbarToggle: BNavbarToggle
            },
            metaInfo: {
                meta: [
                    { name: 'robots', content: 'index, follow' }
                ]
            }
        })
    ], App);
    return App;
}(Vue));
export default App;

import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=ef44806e&lang=pug&"
import script from "./Footer.vue?vue&type=script&lang=ts&"
export * from "./Footer.vue?vue&type=script&lang=ts&"
import style0 from "./Footer.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/www/ePictureMounts.co.uk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ef44806e')) {
      api.createRecord('ef44806e', component.options)
    } else {
      api.reload('ef44806e', component.options)
    }
    module.hot.accept("./Footer.vue?vue&type=template&id=ef44806e&lang=pug&", function () {
      api.rerender('ef44806e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/components/layout/Footer.vue"
export default component.exports
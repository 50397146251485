var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Vue, Component, Watch } from 'vue-property-decorator';
import { BContainer, BNavbar, BNavbarBrand, BNavbarToggle, BCollapse, BNavbarNav, BNavItem, BFormCheckbox, BNavForm, BNavItemDropdown, BDropdownItem } from 'bootstrap-vue';
import { getCart, setVatMode } from '../../../ts/modules/cart';
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.links = [
            { text: 'About', to: 'about' },
            { text: 'Buying Guide', to: 'buying-guide' },
            { text: "Custom Picture Mounts", to: 'custom-mounts' },
            { text: "Museum Picture Mounts", to: 'museum-mounts' }
        ];
        _this.isShowingExVAT = false;
        _this.cartTotal = '0.00';
        return _this;
    }
    Header.prototype.onVATChange = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, setVatMode(this.isShowingExVAT ? 'ex' : 'inc')];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            this.$bus.emit('vat-change');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Header.prototype.refreshCart = function (total) {
        if (total === void 0) { total = null; }
        return __awaiter(this, void 0, void 0, function () {
            var cart;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!total) return [3 /*break*/, 2];
                        return [4 /*yield*/, getCart()];
                    case 1:
                        cart = _a.sent();
                        this.cartTotal = cart.total;
                        return [3 /*break*/, 3];
                    case 2:
                        this.cartTotal = total;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Header.prototype.created = function () {
        this.$bus.on('cart-change', this.refreshCart);
        this.$bus.on('vat-change', this.refreshCart);
        this.refreshCart();
    };
    __decorate([
        Watch('isShowingExVAT')
    ], Header.prototype, "onVATChange", null);
    Header = __decorate([
        Component({
            components: {
                BContainer: BContainer,
                BNavbar: BNavbar,
                BNavbarBrand: BNavbarBrand,
                BNavbarToggle: BNavbarToggle,
                BCollapse: BCollapse,
                BNavbarNav: BNavbarNav,
                BNavItem: BNavItem,
                BFormCheckbox: BFormCheckbox,
                BNavForm: BNavForm,
                BNavItemDropdown: BNavItemDropdown,
                BDropdownItem: BDropdownItem
            }
        })
    ], Header);
    return Header;
}(Vue));
export default Header;

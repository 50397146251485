var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "px-0", attrs: { id: "header", fluid: "" } },
    [
      _c(
        "b-container",
        { staticClass: "px-0" },
        [
          _c(
            "b-navbar",
            { attrs: { toggleable: "xl", type: "dark" } },
            [
              _c("b-navbar-brand", { attrs: { to: { name: "home" } } }, [
                _vm._v("ePictureMounts"),
              ]),
              _c("b-navbar-toggle", {
                attrs: { target: "nav-collapse" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("span", { staticClass: "material-icons" }, [
                          _vm._v("menu"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "b-collapse",
                { attrs: { id: "nav-collapse", "is-nav": "" } },
                [
                  _c(
                    "b-navbar-nav",
                    _vm._l(_vm.links, function (link, idx) {
                      return _c(
                        "span",
                        { key: idx },
                        [
                          typeof link.to === "string"
                            ? _c(
                                "b-nav-item",
                                { attrs: { to: { name: link.to } } },
                                [_vm._v(_vm._s(link.text))]
                              )
                            : _c(
                                "b-nav-item-dropdown",
                                { attrs: { text: link.text } },
                                _vm._l(link.to, function (subLink, subIdx) {
                                  return _c(
                                    "b-dropdown-item",
                                    {
                                      key: idx + "-" + subIdx,
                                      attrs: { to: { name: subLink.to } },
                                    },
                                    [_vm._v(_vm._s(subLink.text))]
                                  )
                                }),
                                1
                              ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "b-navbar-nav",
                    { staticClass: "ml-auto" },
                    [
                      _c("b-nav-item", { attrs: { to: { name: "cart" } } }, [
                        _c("span", { staticClass: "material-icons mr-1" }, [
                          _vm._v("shopping_cart "),
                        ]),
                        _vm._v("Cart (£" + _vm._s(_vm.cartTotal) + ")"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { id: "footer", fluid: "" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { staticClass: "pb-2 pt-3" },
            [
              _c("b-col", [
                _c("p", { staticClass: "mb-1" }, [
                  _vm._v("© 2006-22 Vallaton Limited"),
                ]),
              ]),
              _c(
                "b-col",
                { staticClass: "text-lg-center", attrs: { lg: "3" } },
                [
                  _c("p", { staticClass: "mb-1" }, [
                    _vm._v("Open 9am - 5pm Mon-Fri"),
                  ]),
                ]
              ),
              _c("b-col", { staticClass: "text-lg-right" }, [
                _c("p", { staticClass: "mb-1" }, [
                  _vm._v("Picture & Photo Mount Specialists"),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr", { staticClass: "mt-1 mb-3" }),
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { staticClass: "pb-2" },
            [
              _c("b-col", { staticClass: "mb-3 mb-lg-0" }, [
                _c("p", { staticClass: "mb-1" }, [
                  _c(
                    "a",
                    { attrs: { href: "mailto:sales@epicturemounts.co.uk" } },
                    [
                      _c("span", { staticClass: "material-icons" }, [
                        _vm._v("email"),
                      ]),
                      _vm._v(" sales@epicturemounts.co.uk"),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "mb-1" }, [
                  _c("a", { attrs: { href: "tel:01246554338" } }, [
                    _c("span", { staticClass: "material-icons" }, [
                      _vm._v("phone"),
                    ]),
                    _vm._v(" 01246 554338"),
                  ]),
                ]),
                _c("p", { staticClass: "mb-1" }, [
                  _c("span", { staticClass: "material-icons" }, [
                    _vm._v("home"),
                  ]),
                  _vm._v(" 11a Old Hall Road, S40 3RG"),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "instagram",
                    attrs: {
                      href: "https://www.instagram.com/epicture_mounts",
                      target: "_blank",
                      rel: "noopener",
                    },
                  },
                  [
                    _c(
                      "b-row",
                      { attrs: { "no-gutters": "", "align-v": "center" } },
                      [
                        _c(
                          "b-col",
                          { staticClass: "pr-1", attrs: { cols: "auto" } },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  role: "img",
                                  viewBox: "0 0 24 24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c("b-col", [_vm._v("@epicture_mounts")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "b-col",
                {
                  staticClass: "mb-3 mb-lg-0 text-lg-center",
                  attrs: { lg: "4" },
                },
                [
                  _c(
                    "p",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "terms-and-conditions" } } },
                        [_vm._v("Terms and Conditions")]
                      ),
                      _vm._v(" / "),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "privacy-policy" } } },
                        [_vm._v("Privacy Policy")]
                      ),
                      _vm._v(" / "),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "cookie-policy" } } },
                        [_vm._v("Cookie Policy")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "website-terms-of-use" } } },
                        [_vm._v("Website Terms of Use")]
                      ),
                      _vm._v(" / "),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "acceptable-use-policy" } } },
                        [_vm._v("Acceptable Use Policy")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("b-col", { staticClass: "text-lg-right" }, [
                _c("p", { staticClass: "mb-1" }, [
                  _vm._v("Company Reg. No. 05763022"),
                ]),
                _c("p", { staticClass: "mb-1" }, [_vm._v("VAT No. 880302543")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default {
    set: function (name, value, daysToExpire, path) {
        if (daysToExpire === void 0) { daysToExpire = 7; }
        if (path === void 0) { path = '/'; }
        var date = new Date();
        date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 100));
        document.cookie = name + '=' + value + ';' + 'expires=' + date.toUTCString() + ';' + 'path=' + path + ';';
    },
    get: function (name) {
        // Get a cookie
        var cookie_value = "", current_cookie = "", name_expr = name + "=", all_cookies = document.cookie.split(';'), n = all_cookies.length;
        for (var i = 0; i < n; i++) {
            current_cookie = all_cookies[i].trim();
            if (current_cookie.indexOf(name_expr) === 0) {
                cookie_value = current_cookie.substring(name_expr.length, current_cookie.length);
                break;
            }
        }
        return cookie_value;
    }
};

import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueGtm from '@gtm-support/vue2-gtm';
import 'cookieconsent';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
Sentry.init({
    Vue: Vue,
    dsn: "https://a29270537b5d4c1f9072db870f02227e@o462017.ingest.sentry.io/6273229",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        }),
    ],
    tracesSampleRate: process.env.ENVIRONMENT == 'production' ? 0.25 : 1.0,
});
window.cookieconsent.initialise({
    "palette": {
        "popup": {
            "background": "#edeff5",
            "text": "#838391"
        },
        "button": {
            "background": "#4b81e8"
        }
    },
    "theme": "edgeless",
    "position": "bottom-left",
    "content": {
        "message": "Our website sets a cookie to track your shopping cart while you browse, and includes third-party code that anonymously reports your visit so we can improve our website in the future.",
        "href": "/customer-information/cookie-policy"
    }
});
import App from '@/vue/App.vue';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
// Add the sitemap and robots.txt
var sitemap = require('./sitemap.xml');
var robots = require('./robots.txt');
import '@/css/style.css';
import router from './ts/router';
import cookie from './ts/modules/cookie';
cookie.set('web_source', 'www.epicturemounts.co.uk');
Vue.use(VueMeta);
Vue.use(VueGtm, {
    id: 'GTM-KMK3WHX',
    vueRouter: router
});
new Vue({
    el: '#app',
    router: router,
    components: { App: App },
    template: '<App/>'
});

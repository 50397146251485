import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/vue/pages/Home.vue';
Vue.use(Router);
var router = new Router({
    mode: 'history',
    scrollBehavior: function () {
        // always scroll to top
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/',
            component: Home,
            name: 'home'
        },
        {
            path: '/about-us',
            component: function () { return import(/* webpackChunkName: "about-us" */ '@/vue/pages/AboutUs.vue'); },
            name: 'about'
        },
        {
            path: '/picture-mount-buying-guide',
            component: function () { return import(/* webpackChunkName: "about-us" */ '@/vue/pages/BuyingGuide.vue'); },
            name: 'buying-guide'
        },
        {
            path: '/picture-mounts/whitecore-and-conservation-cut-to-size',
            component: function () { return import(/* webpackChunkName: "purchase-mounts" */ '@/vue/pages/CustomMounts.vue'); },
            name: 'custom-mounts'
        },
        {
            path: '/picture-mounts/museum-grade-cut-to-size',
            component: function () { return import(/* webpackChunkName: "purchase-mounts" */ '@/vue/pages/MuseumMounts.vue'); },
            name: 'museum-mounts'
        },
        {
            path: '/cart',
            component: function () { return import(/* webpackChunkName: "cart" */ '@/vue/pages/Cart/Cart.vue'); },
            name: 'cart'
        },
        {
            path: '/cart/checkout',
            component: function () { return import(/* webpackChunkName: "cart" */ '@/vue/pages/Cart/Checkout.vue'); },
            name: 'checkout'
        },
        {
            path: '/cart/thank-you',
            component: function () { return import(/* webackChunkName: "cart" */ '@/vue/pages/Cart/ThankYou.vue'); },
            name: 'thankyou'
        },
        {
            path: '/customer-information/terms-and-conditions',
            component: function () { return import(/* webpackChunkName: "customer-info" */ '@/vue/pages/CustomerInformation/TermsAndConditions.vue'); },
            name: 'terms-and-conditions'
        },
        {
            path: '/customer-information/privacy-policy',
            component: function () { return import(/* webpackChunkName: "customer-info" */ '@/vue/pages/CustomerInformation/PrivacyPolicy.vue'); },
            name: 'privacy-policy'
        },
        {
            path: '/customer-information/cookie-policy',
            component: function () { return import(/* webpackChunkName: "customer-info" */ '@/vue/pages/CustomerInformation/CookiePolicy.vue'); },
            name: 'cookie-policy'
        },
        {
            path: '/customer-information/website-terms-of-use',
            component: function () { return import(/* webpackChunkName: "customer-info" */ '@/vue/pages/CustomerInformation/WebsiteTermsOfUse.vue'); },
            name: 'website-terms-of-use'
        },
        {
            path: '/customer-information/acceptable-use-policy',
            component: function () { return import(/* webpackChunkName: "customer-info" */ '@/vue/pages/CustomerInformation/AcceptableUsePolicy.vue'); },
            name: 'acceptable-use-policy'
        },
        {
            path: "*",
            component: function () { return import(/* webpackChunkName: "not-found" */ '@/vue/pages/PageNotFound.vue'); }
        }
    ]
});
export default router;

import axios from 'axios';
import { getCartId, setCartId } from './cart';
var axiosInstance = axios.create({
    baseURL: '/api/'
});
axiosInstance.interceptors.request.use(function (config) {
    var cartId = getCartId();
    if (cartId && cartId != 'null') {
        config.headers['cart_id'] = getCartId();
    }
    config.headers['web_source'] = 'www.epicturemounts.co.uk';
    return config;
});
axiosInstance.interceptors.response.use(function (response) {
    if (response.headers.cart_id) {
        setCartId(response.headers.cart_id);
    }
    else {
        setCartId(null);
    }
    return response;
});
export default axiosInstance;

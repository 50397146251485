var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column min-vh-100" },
    [
      _c("app-header"),
      _c("div", { staticClass: "flex-fill" }, [
        _c("div", { staticClass: "pt-3" }, [_c("router-view")], 1),
      ]),
      _c("app-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
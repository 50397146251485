var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { BContainer, BRow, BCol, BCard, BCardBody, BButton } from 'bootstrap-vue';
import LogoImg from '@/img/epicturemounts-logo.jpg';
import MuseumMountImg from '@/img/museum-grade-picture-mounts.jpg';
import WCMountImg from '@/img/whitecore-conservation-picture-mounts.jpg';
var Home = /** @class */ (function (_super) {
    __extends(Home, _super);
    function Home() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.logoImg = LogoImg;
        _this.logoImgWebp = LogoImg.replace('.jpg', '.webp');
        _this.museumMountImg = MuseumMountImg;
        _this.museumMountImgWebp = MuseumMountImg.replace('.jpg', '.webp');
        _this.wcMountImg = WCMountImg;
        _this.wcMountImgWebp = WCMountImg.replace('.jpg', '.webp');
        return _this;
    }
    Home = __decorate([
        Component({
            components: {
                BContainer: BContainer,
                BRow: BRow,
                BCol: BCol,
                BCard: BCard,
                BCardBody: BCardBody,
                BButton: BButton
            },
            metaInfo: {
                title: 'Bespoke and Standard Size Picture & Photo Mounts - ePictureMounts',
                meta: [
                    {
                        name: 'description',
                        content: 'Purchase bespoke and standard size picture & photo mounts online with UK wide delivery. We offer over 20 whitecore and conservation grade mountboard colours cut to almost any size, as well as museum grade mount options for the absolute best possible protection.'
                    },
                    {
                        name: 'keywords',
                        content: 'picture mounts, photo mounts, conservation mountboard, museum grade mountboard'
                    }
                ]
            }
        })
    ], Home);
    return Home;
}(Vue));
export default Home;

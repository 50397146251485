var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "home" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c("b-col", [
                _c("h1", [_vm._v("Picture & Photo Mount Specialists ")]),
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    "At ePictureMounts we are your one-stop shop for high quality Picture & Photo Mounts. We are a "
                  ),
                  _c("a", { attrs: { href: "https://www.fineart.co.uk/" } }, [
                    _vm._v("Fine Art Trade Guild"),
                  ]),
                  _vm._v(
                    " commended picture framers in Chesterfield, Derbyshire who specialise in supplying bespoke and standard sized picture mounts to the UK and Worldwide."
                  ),
                ]),
              ]),
              _c("b-col", { attrs: { sm: "4", lg: "3", xl: "2" } }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.instagram.com/epicture_mounts/",
                      target: "_blank",
                    },
                  },
                  [
                    _c("picture", [
                      _c("source", {
                        attrs: { srcset: _vm.logoImgWebp, type: "image/webp" },
                      }),
                      _c("source", {
                        attrs: { srcset: _vm.logoImg, type: "image/jpeg" },
                      }),
                      _c("img", {
                        staticClass: "img-fluid",
                        attrs: {
                          src: _vm.logoImg,
                          alt: "ePictureMounts.co.uk - Picture Mount Specialists",
                        },
                      }),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { staticClass: "bg-light px-0", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "my-3 py-5" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-3 mb-lg-0",
                      attrs: { "offset-lg": "1", lg: "5", md: "6" },
                    },
                    [
                      _c(
                        "b-card",
                        {
                          attrs: {
                            title: "Bespoke Picture Mounts",
                            align: "center",
                            "no-body": "",
                          },
                        },
                        [
                          _c("picture", [
                            _c("source", {
                              attrs: {
                                srcset: _vm.wcMountImgWebp,
                                type: "image/webp",
                              },
                            }),
                            _c("source", {
                              attrs: {
                                srcset: _vm.wcMountImg,
                                type: "image/jpeg",
                              },
                            }),
                            _c("img", {
                              staticClass: "card-img-top",
                              attrs: {
                                src: _vm.wcMountImg,
                                alt: "Museum Grade Mountboard - ePictureMounts",
                              },
                            }),
                          ]),
                          _c(
                            "b-card-body",
                            [
                              _c("p", [
                                _vm._v(
                                  "Design your perfect picture mount accurate to the nearest millimetre. Choose from 20+ different quality mountboard colours from Larson-Juhl."
                                ),
                              ]),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "brand",
                                    size: "lg",
                                    to: { name: "custom-mounts" },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "material-icons" },
                                    [_vm._v("navigate_next")]
                                  ),
                                  _vm._v(" Buy Custom Picture Mounts"),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-3 mb-lg-0",
                      attrs: { lg: "5", md: "6" },
                    },
                    [
                      _c(
                        "b-card",
                        {
                          attrs: {
                            title: "Museum Grade Picture Mounts",
                            align: "center",
                            "no-body": "",
                          },
                        },
                        [
                          _c("picture", [
                            _c("source", {
                              attrs: {
                                srcset: _vm.museumMountImgWebp,
                                type: "image/webp",
                              },
                            }),
                            _c("source", {
                              attrs: {
                                srcset: _vm.museumMountImg,
                                type: "image/jpeg",
                              },
                            }),
                            _c("img", {
                              staticClass: "card-img-top",
                              attrs: {
                                src: _vm.museumMountImg,
                                alt: "Museum Grade Mountboard - ePictureMounts",
                              },
                            }),
                          ]),
                          _c(
                            "b-card-body",
                            [
                              _c("p", [
                                _vm._v(
                                  "Purchase museum quality mountboards to protect your artwork and photographs to the highest level."
                                ),
                              ]),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "brand",
                                    size: "lg",
                                    to: { name: "museum-mounts" },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "material-icons" },
                                    [_vm._v("navigate_next")]
                                  ),
                                  _vm._v(" Buy Museum Quality Mounts"),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-container", { staticClass: "py-5" }, [
        _c("h2", [_vm._v("Custom Size Picture Frame Mounts")]),
        _c("p", { staticClass: "mb-0" }, [
          _vm._v(
            "At ePictureMounts you can buy custom size picture & photo mounts in any size up to a maximum of 1120x1630mm. Each mountboard comes with a crisp and neat 45 degree bevel on the inner edge cut by computerised mountboard cutter that is accurate to 0.02mm.\nWe only offer top quality whitecore or conservation mountboard on our site, unlike many other sellers of mountboard in the UK who sell cheaper creamcore mountboard."
          ),
        ]),
      ]),
      _c(
        "b-container",
        { staticClass: "bg-light py-5 px-0", attrs: { fluid: "" } },
        [
          _c("b-container", [
            _c("h2", [_vm._v("UK Wide Mail Order Delivery")]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                "We take pride in our packaging of your orders to make sure that your order of picture mounts (whether just an order of 1 or 1000) arrives to you in prestine condition. We only use recognised UK delivery companies such as Royal Mail & Parcelforce with no more than a 48 hour delivery window from the date of dispatch."
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }